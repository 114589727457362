// import React from "react";
import React from "react";
// Styles import
import styled from "styled-components";
import SVG from "react-inlinesvg";
// imgs import
import minus from "../../../../assets/images/DesignSystemIcons/minus.svg";
import plus from "../../../../assets/images/DesignSystemIcons/plus.svg";
import { DSColors } from "../../../../styles/variables";

export interface UpDownButtonProps {
  value: number;
  increment?: number;
  onPlusMinusClick: (value: number) => void;
  minvalue?: number;
}
const Container = styled.div`
  display: flex;
`;
const Wrapper = styled.div`
  border: 1px solid ${DSColors.SGLight02};
  border-radius: 4px;
  background-color: ${DSColors.white};
  width: 155px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 45px;
  font-family: "DM Sans", serif;
  font-weight: 500;
  font-size: 16px;
  path {
    fill: #08b77f;
  }

  > div:hover {
    cursor: pointer;
  }
`;
const MinusSvg = styled(SVG)<{ value: number; minvalue: number }>`
  /* margin-bottom: 5px; */
  filter: ${(props) =>
    props.value > props.minvalue
      ? "unset"
      : "invert(74%) sepia(3%) saturate(362%) hue-rotate(185deg) brightness(93%) contrast(86%)"};
`;
const PlusSvg = styled(SVG)``;
export const Quantite: React.FC<UpDownButtonProps> = ({
  value = 0,
  increment,
  onPlusMinusClick,
  minvalue = 0,
}) => {
  return (
    <Container>
      <Wrapper>
        <ButtonsWrapper>
          <div
            onClick={() =>
              onPlusMinusClick(
                value === minvalue ? minvalue : (value -= increment || 1)
              )
            }
          >
            <MinusSvg value={value} src={minus} minvalue={minvalue} />
          </div>
          {value}
          <div
            onClick={() => {
              onPlusMinusClick((value += increment || 1));
            }}
          >
            <PlusSvg src={plus} />
          </div>
        </ButtonsWrapper>
      </Wrapper>
    </Container>
  );
};
